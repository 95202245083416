import React from "react";
import Comment from "../comment/Comment";
import "./CommentList.css";

const CommentList = ({ postId, comments, onLike, onCommentPosted }) => {
    return (
        <div className="comment-list">
            {comments.map(comment => (
                <Comment
                    comment={comment}
                    commentId={comment.id}
                    postId={postId}
                    key={comment.id}
                    onLike={onLike}
                    onCommentPosted={onCommentPosted}
                />
            ))}
        </div>
    );
};

export default CommentList;
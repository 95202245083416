import React, { useState, useEffect } from "react";
import axios from "axios";
import "./LandingPage.css";

function LandingPage() {
    // Estado para controlar si la imagen está volteada
    const [isFlipped, setIsFlipped] = useState(false);
    // Estado para almacenar los datos del perfil
    const [profileData, setProfileData] = useState(null);
    // Estado para controlar la carga
    const [loading, setLoading] = useState(true);
    // Estado para manejar errores
    const [error, setError] = useState(null);

    // URL base de la API de perfil obtenida de las variables de entorno
    const backendURL = process.env.REACT_APP_BACKEND_URL

    // Efecto para obtener los datos del perfil al cargar la página
    useEffect(() => {
        axios.get(`${backendURL}/api/v1/profile/profile/`)
            .then((response) => {
                setProfileData(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error al obtener los datos del perfil:", error);
                setError(error);
                setLoading(false);
            });
    }, [backendURL]);

    // Función para manejar el clic en la imagen
    const handleImageClick = () => {
        // Si los datos del perfil no están cargados, no hacer nada
        if (!profileData) return;

        const newIsFlipped = !isFlipped;
        setIsFlipped(newIsFlipped);

        // Determinamos el tipo de imagen en función de si está volteado
        const imageType = newIsFlipped ? "secondary" : "main";

        // Registramos la vista para el tipo de imagen correspondiente
        registerView(imageType);
    };

    // Función para registrar la vista de una imagen
    const registerView = (imageType) => {
        axios.post(`${backendURL}/api/v1/interactions/profile_images/${imageType}/view/`)
            .then((response) => {
                console.log(`Se ha registrado una vista para la imagen con tipo ${imageType}`);
            })
            .catch((error) => {
                console.error(`Error al registrar la vista para la imagen con tipo ${imageType}:`, error);
            });
    };

    // Mostrar indicador de carga si los datos aún no están disponibles
    if (loading) {
        return <div>Cargando...</div>;
    }

    // Mostrar mensaje de error si ocurrió un error al cargar los datos
    if (error) {
        return <div>Error al cargar los datos del perfil: {error.message}</div>;
    }

    return (
        <div className="landing-page">
            <div className="landing-page-header">
                <div className="flip-container" onClick={handleImageClick}>
                    <div className={`flipper ${isFlipped ? 'flipped' : ''}`}>
                        <div className="front">
                            <img src={profileData.main_photo.url} alt="Foto de Sebastian Tobar Quintero" />
                        </div>
                        <div className="back">
                            <img src={profileData.secondary_photo.url} alt="Foto secundaria de Sebastian Tobar Quintero" />
                        </div>
                    </div>
                </div>
                <h1>{profileData.title}</h1>
                <p>
                    {profileData.description}
                </p>
            </div>
            <div className="landing-page-footer">
                <div className="social-links">
                    <a
                        href="https://co.linkedin.com/in/sebastq/es/?utm_source=sebastians_blog&utm_medium=linkedin&utm_campaign=sebastians_blog_lading_page"
                        target="_blank"
                        rel="noreferrer"
                    >
                        LinkedIn
                    </a>
                    <a
                        href="https://github.com/sebastiantq-protonmail/?utm_source=sebastians_blog&utm_medium=github&utm_campaign=sebastians_blog_lading_page"
                        target="_blank"
                        rel="noreferrer"
                    >
                        GitHub
                    </a>
                    <a
                        href="https://www.instagram.com/sebastq_/?utm_source=sebastians_blog&utm_medium=instagram&utm_campaign=sebastians_blog_lading_page"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Instagram
                    </a>
                    <a
                        href="https://x.com/sebastq_/?utm_source=sebastians_blog&utm_medium=twitter&utm_campaign=sebastians_blog_lading_page"
                        target="_blank"
                        rel="noreferrer"
                    >
                        X
                    </a>
                </div>
                <p>© 202X Sebastian. Todos los derechos reservados.</p>
                <p>¿Esto sirve de algo? 😂</p>
            </div>
        </div>
    );
}

export default LandingPage;

// Comment.jsx
import React, { useState } from "react";
import axios from "axios";
import CommentForm from "../commentForm/CommentForm";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import "./Comment.css";

const Comment = ({ comment, commentId, postId, onLike, onCommentPosted }) => {
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [localComment, setLocalComment] = useState({ ...comment, children: comment.children || [] });
    const [showReplyForm, setShowReplyForm] = useState(false);

    const handleLike = () => {
        const oldChildren = localComment.children;
        const oldLiked = localComment.liked_by_user;
        axios.post(`${backendURL}/api/v1/interactions/comments/${localComment.id}/like/`)
            .then((response) => {
                // Mantener children y liked_by_user en caso de que falten
                const updated = response.data;
                updated.children = oldChildren; 
                if (updated.liked_by_user === undefined) {
                    updated.liked_by_user = oldLiked;
                }
                setLocalComment(updated);
                onLike(localComment.id, updated.likes);
            });
    };

    const handleReplyClick = () => {
        setShowReplyForm(!showReplyForm);
    };

    const handleSubCommentPosted = (newComment) => {
        const newChild = { ...newComment, children: newComment.children || [], liked_by_user: newComment.liked_by_user || false };
        setLocalComment(prev => ({ ...prev, children: [newChild, ...prev.children] }));
        setShowReplyForm(false);
    };

    return (
        <div className="comment">
            <p>{localComment.content}</p>
            <div className="comment-actions">
                <div 
                    className={`like-button ${localComment.liked_by_user ? 'liked' : ''}`} 
                    onClick={handleLike}
                >
                    {localComment.liked_by_user ? <FaHeart className="heart-icon"/> : <FaRegHeart className="heart-icon"/>} 
                    <span style={{marginLeft:'5px'}}>{localComment.likes}</span>
                </div>
                <div className="reply-button" onClick={handleReplyClick}>Responder</div>
            </div>
            {showReplyForm && (
                <CommentForm
                    postId={postId}
                    parentId={commentId}
                    onCommentPosted={handleSubCommentPosted}
                />
            )}
            {localComment.children && localComment.children.length > 0 && (
                <div className="comment-children">
                    {localComment.children.map((childComment) => (
                        <Comment
                            key={childComment.id}
                            comment={childComment}
                            commentId={childComment.id}
                            postId={postId}
                            onLike={onLike}
                            onCommentPosted={onCommentPosted}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default Comment;

import React from "react";
import "./MicroPost.css";

const MicroPost = (props) => {
  const { title, subtitle, date, image_url, content, author } = props.microPost; // eslint-disable-line no-unused-vars

  return (
    <>
      <hr />
      <div className="micro-post">
        <div className="micro-post-details">
          <h2>{title}</h2>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
          <p>{date}</p>
          <p>{author}</p>
        </div>
      </div>
      <hr />
    </>
  );
};

export default MicroPost;

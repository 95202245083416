import React, { useEffect, useState } from "react";
import axios from "axios";
import CommentList from "../commentList/CommentList";
import CommentForm from "../commentForm/CommentForm";
import "./PostDetail.css";
import { useParams } from "react-router-dom";
import { FaHeart, FaRegHeart, FaEye } from "react-icons/fa";

const PostDetail = () => {
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [post, setPost] = useState(null);
    const [comments, setComments] = useState([]); 
    const [loadingComments, setLoadingComments] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        // Obtener el post por su id
        axios.get(`${backendURL}/api/v1/blog/${id}/`).then((response) => {
            const postData = response.data;
            // Aseguramos que postData.liked_by_user exista
            if (postData.liked_by_user === undefined) {
                postData.liked_by_user = false;
            }
            setPost(postData);
        });

        // Incrementar el contador de visualizaciones
        axios.post(`${backendURL}/api/v1/interactions/objects/posts/${id}/view`);

        // Obtener comentarios inicialmente
        axios.get(`${backendURL}/api/v1/interactions/objects/posts/${id}/comments/`)
            .then(response => {
                const flatComments = response.data;
                const nestedComments = buildNestedComments(flatComments);
                setComments(nestedComments);
            })
            .finally(() => setLoadingComments(false));
    }, [backendURL, id]);

    const buildNestedComments = (commentArray) => {
        const commentMap = {};
        commentArray.forEach(comment => {
            comment.children = comment.children || [];
            commentMap[comment.id] = comment;
        });

        const rootComments = [];
        commentArray.forEach(comment => {
            if (comment.parent_comment_id) {
                const parent = commentMap[comment.parent_comment_id];
                if (parent) {
                    parent.children.push(comment);
                }
            } else {
                rootComments.push(comment);
            }
        });

        return rootComments;
    };

    const addCommentToStructure = (currentList, newComment) => {
        // Aseguramos siempre children
        const safeNewComment = { ...newComment, children: newComment.children || [] };
        if (!safeNewComment.parent_comment_id) {
            // comentario de primer nivel
            return [safeNewComment, ...currentList];
        } else {
            // subcomentario
            return currentList.map(c => {
                if (c.id === safeNewComment.parent_comment_id) {
                    return { ...c, children: [safeNewComment, ...c.children] };
                }
                if (c.children && c.children.length > 0) {
                    return { ...c, children: addCommentToStructure(c.children, safeNewComment) };
                }
                return c;
            });
        }
    };

    const handleCommentPosted = (newComment) => {
        // Insertar el nuevo comentario en la estructura
        setComments(prevComments => addCommentToStructure(prevComments, newComment));
    };

    const handleLike = () => {
        axios.post(`${backendURL}/api/v1/interactions/objects/posts/${id}/like`).then((response) => {
            const updatedPost = response.data;
            if (updatedPost.liked_by_user === undefined) {
                updatedPost.liked_by_user = false;
            }
            setPost(updatedPost);
        });
    };

    const updateCommentLikes = (commentList, commentId, updatedLikes) => {
        return commentList.map(c => {
            if (c.id === commentId) {
                return { ...c, likes: updatedLikes };
            }
            if (c.children && c.children.length > 0) {
                return { ...c, children: updateCommentLikes(c.children, commentId, updatedLikes) };
            }
            return c;
        });
    };

    const handleCommentLike = (commentId, updatedLikes) => {
        setComments(prevComments => updateCommentLikes(prevComments, commentId, updatedLikes));
    };

    if (!post) {
        return <div>Cargando...</div>;
    }

    return (
        <div className="post-detail">
            <div className="post-detail-content">
                <h1>{post.title}</h1>
                <h2>{post.subtitle}</h2>
                {post.image_url && (
                    <div className="post-detail-image">
                        <img src={post.image_url} alt={post.title} />
                    </div>
                )}
                <p>{`Publicado el ${post.date} por ${post.author}`}</p>
                <div dangerouslySetInnerHTML={{ __html: post.content }}></div>
                <div className="post-detail-stats">
                    <div className="post-like-container" onClick={handleLike}>
                        {post.liked_by_user ? <FaHeart className="heart-icon liked"/> : <FaRegHeart className="heart-icon"/>}
                        <span className="like-count">{post.likes || 0}</span>
                    </div>
                    <div className="post-views-container">
                        <FaEye className="heart-icon"/>
                        <span className="views-count">{post.views || 0} vistas</span>
                    </div>
                </div>
                <CommentForm postId={post.id} onCommentPosted={handleCommentPosted} />
                {loadingComments && <div><br />Cargando comentarios...</div>}
                {!loadingComments && (
                    <CommentList
                        postId={post.id}
                        comments={comments}
                        onLike={handleCommentLike}
                        onCommentPosted={handleCommentPosted}
                    />
                )}
            </div>
        </div>
    );
};

export default PostDetail;
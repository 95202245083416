import React, { useState } from "react";
import axios from "axios";
import "./CommentForm.css";

const CommentForm = ({ postId, parentId = null, onCommentPosted }) => {
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [content, setContent] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        axios.post(`${backendURL}/api/v1/interactions/objects/posts/${postId}/comments/`, {
            content,
            parent_comment_id: parentId,
        }).then((response) => {
            const newComment = response.data;
            // Aseguramos children vacío si no existe
            newComment.children = newComment.children || [];
            newComment.liked_by_user = newComment.liked_by_user || false;
            setContent("");
            onCommentPosted && onCommentPosted(newComment);
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <form className="comment-form" onSubmit={handleSubmit}>
            <textarea
                value={content}
                onChange={(e) => setContent(e.target.value)}
                placeholder="Escribe un comentario..."
                required
                disabled={loading}
            ></textarea>
            <button type="submit" disabled={loading}>Enviar</button>
        </form>
    );
};

export default CommentForm;

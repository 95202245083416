// Blog.js
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./Blog.css";
import Post from "../post/Post";
import MicroPost from "../microPost/MicroPost";

function Blog() {
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [posts, setPosts] = useState([]);
  const [isBloggingOpen, setIsBloggingOpen] = useState(true);
  const [isMicrobloggingOpen, setIsMicrobloggingOpen] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("personal"); // eslint-disable-next-line
  const [error, setError] = useState(null); // Estado para manejar errores 

  const headerRef = useRef(null);
  const bodyRef = useRef(null);
  const scrollTimeout = useRef(null);

  // const postExample = {
  //   _id: 1,
  //   title: "Mi primer post",
  //   subtitle: "¡Bienvenidos a mi blog!",
  //   date: "2023-04-02",
  //   // imageUrl: "https://mi-sitio.com/imagen-post.jpg",
  //   image_url: undefined,
  //   content: "¡Hola a todos! Este es mi primer post en mi nuevo blog. Estoy muy emocionado de compartir mis pensamientos e ideas con ustedes. Espero que disfruten leyendo mis publicaciones tanto como yo disfruto escribiéndolas.",
  //   author: "Juan Perez",
  //   category: 1,
  //   views: 0,
  //   likes: 0
  // }

  useEffect(() => {
    let url = `${backendURL}/api/v1/blog/`;
    if (selectedCategory !== "all") {
      const categoryNumber = selectedCategory === "personal" ? 1 : 2;
      url += `?category=${categoryNumber}`;
    }

    //console.log("Llamando a la API con URL:", url); // Depuración

    axios
      .get(url)
      .then((response) => {
        //console.log("Datos recibidos del backend:", response.data); // Depuración
        setPosts(response.data);
        setError(null); // Limpiar errores previos
      })
      .catch((error) => {
        //console.error("Error al obtener las publicaciones:", error);
        setError("No se pudieron cargar las publicaciones. Inténtalo de nuevo más tarde.");
      });
  }, [backendURL, selectedCategory]);

  useEffect(() => {
    let prevScrollPos = window.pageYOffset;

    function handleScroll() {
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }

      scrollTimeout.current = setTimeout(() => {
        const currentScrollPos = window.pageYOffset;

        if (prevScrollPos > currentScrollPos) {
          headerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        } else {
          bodyRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }

        prevScrollPos = currentScrollPos;
      }, 100);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const bloggingPosts = posts.filter(
    (post) =>
      post.subtitle !== "" ||
      post.content.length > 333 ||
      post.image_url !== ""
  );

  const microbloggingPosts = posts.filter(
    (post) =>
      post.subtitle === "" &&
      post.content.length <= 333 &&
      post.image_url === ""
  );

  // Manejar el cambio de categoría
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  return (
    <div className="blog-page">
      <div ref={headerRef} className="blog-page-header">
        <h1>Blog</h1>
        <p>Comparto un poco de lo que se me pasa por la mente.</p>
      </div>
      <div className="blog-page-body">
        <div
          className={`blog-section ${isBloggingOpen ? "open" : ""}`}
          ref={bodyRef}
        >
          <div className="section-header">
            <h2 onClick={() => setIsBloggingOpen(!isBloggingOpen)}>Blogging</h2>
            {/* Lista Desplegable de Categorías */}
            <div>
              <label htmlFor="category-dropdown">Categoría:</label>
              <select
                value={selectedCategory}
                onChange={handleCategoryChange}
                className="category-dropdown"
              >
                <option value="all">Todas</option>
                <option value="personal">Personal</option>
                <option value="arnold">ARNOLD</option>
              </select>
            </div>
          </div>
          {isBloggingOpen && (
            <div>
              {[...bloggingPosts].reverse().map((post) => (
                <Post key={post.id.toString()} post={post} />
              ))}
            </div>
          )}
        </div>
        <div
          className={`microblogging-section ${isMicrobloggingOpen ? "open" : ""}`}
        >
          <div className="section-header">
            <h2 onClick={() => setIsMicrobloggingOpen(!isMicrobloggingOpen)}>
              Microblogging
            </h2>
          </div>
          {isMicrobloggingOpen && (
            <div>
              {[...microbloggingPosts].reverse().map((post) => (
                <MicroPost key={post.id.toString()} microPost={post} />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Blog;
